
import { Options, mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import cloneDeep from 'lodash/cloneDeep'
import { ICampaign, ITrafficSource } from '@/utils/types'
import { ACTION_CAMPAIGN, ACTION_TRAFFIC_SOURCE } from '@/store/actions'
import CLoading from '@/components/common/ui/CLoading.vue'
import TestModeSelector from '@/components/common/ui/TestModeSelector.vue'
import BaseFormDialogMixin from '../common/mixins/BaseFormDialogMixin.vue'

@Options({
  components: { CLoading, TestModeSelector },
  directives: { maska },
  emits: ['update:onSelect'],
})
export default class TrafficSourceFormModal extends mixins(BaseFormDialogMixin) {
  @Prop()
  data!: ITrafficSource

  @Prop()
  campaignId!: string

  loading = true
  isTestMode = false
  selectedCampaign: ICampaign = {}
  form: ITrafficSource = {
    _id: '',
    source_name: '',
    app_id: '',
    is_active: false,
    is_test_mode: false,
  }

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get requiredRule() {
    const requiredRule = {
      source_name: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.traffic_source.source_name'),
      }),
    }
    return requiredRule
  }

  get modalTitle() {
    return this.isNew ? this.$t('label.traffic_source.add_new') : this.$t('label.traffic_source.edit')
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }

        await this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    const { dispatch } = this.$store
    let item = null
    if (this.isNew) {
      this.form.app_id = this.selectedAppId
      this.form.campaign_id = this.campaignId

      item = await dispatch(ACTION_TRAFFIC_SOURCE.ADD, {
        ...this.form,
      })
    } else {
      item = await dispatch(ACTION_TRAFFIC_SOURCE.UPDATE, {
        _id: this.data._id,
        ...this.form,
      })
    }

    if (item) {
      this.$emit('update:onSelect', item)

      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    this.visible = false
  }

  async created() {
    this.selectedCampaign = await this.$store.dispatch(ACTION_CAMPAIGN.LOAD_ITEM, this.campaignId)

    this.form = { ...this.form, ...cloneDeep(this.data) }
    this.parentForm = this.form
    this.initForm = cloneDeep(this.parentForm)
    this.loading = false
    this.$nextTick(() => {
      this.$refs.formRef.validate()
    })
  }
}
