
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { IApp, ICampaign, IDateRange, ITrafficSource } from '@/utils/types'
import { Watch } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'
import { ACTION_APP, ACTION_CAMPAIGN, ACTION_TRAFFIC_SOURCE } from '@/store/actions'
import CLoading from '@/components/common/ui/CLoading.vue'
import TrafficSourceFormModal from '@/components/traffic-sources/TrafficSourceFormModal.vue'
import QRCodeFormModal from '@/components/traffic-sources/QRCodeFormModal.vue'
import TagContent from '@/components/common/ui/TagContent.vue'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'
import { lastNMonth, now } from '@/utils/datetime'
import DateRangeSelector from '@/components/common/ui/DateRangeSelector.vue'
import TestModeSelector from '@/components/common/ui/TestModeSelector.vue'

@Options({
  components: {
    CLoading,
    TrafficSourceFormModal,
    QRCodeFormModal,
    TagContent,
    DateRangeSelector,
    TestModeSelector,
  },
  directives: { maska },
  emits: ['update:changeTab'],
})
export default class TrafficSourceList extends mixins(BaseFormMixin) {
  loading = true
  modalVisible = false
  modalQRCodeVisible = false
  selectedTrafficSource: ITrafficSource = {}
  selectingApp: IApp = {}
  selectedCampaignId = ''
  campaigns: ICampaign[] = []
  isTestMode = false

  dateRange: IDateRange = {
    from: '',
    to: '',
  }

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get trafficSources() {
    const result = cloneDeep(this.$store.getters.trafficSources) || []
    if (result.length > 0) {
      for (const element of result) {
        element.tracking_url = this.getTrackingURL(element)
      }
    }

    return result
  }

  get campaignId() {
    return this.$route.params.campaign_id || ''
  }

  get getIsTestMode() {
    return this.$route.params.is_test_mode === 'true' || false
  }

  @Watch('isTestMode')
  async isTestModeChange() {
    if (this.firstLoad) {
      return
    }

    this.loading = true
    this.firstLoad = true
    await this.loadData()
    if (this.campaigns.length > 0) {
      this.selectedCampaignId = this.campaigns[0]._id ?? ''
    }

    await this.loadTrafficSource()
    this.firstLoad = false
    this.loading = false
  }

  @Watch('campaignId', { immediate: true })
  async appIdChanged() {
    if (!this.selectedAppId) {
      return
    }
    this.firstLoad = true

    this.dateRange = {
      from: lastNMonth(1),
      to: now(),
    }

    this.selectingApp = await this.$store.dispatch(ACTION_APP.LOAD_ITEM, this.selectedAppId)
    this.isTestMode = this.getIsTestMode
    await this.loadData()
    this.selectedCampaignId = this.campaignId
    await this.loadTrafficSource()

    this.firstLoad = false
    this.loading = false
  }

  @Watch('selectedCampaignId')
  handleChangeCampaign() {
    if (this.firstLoad) {
      return
    }
    this.loadTrafficSource()
  }

  changeDateRange(value) {
    if (this.firstLoad) {
      return
    }

    this.dateRange = value
    this.loadTrafficSource()
  }

  async loadData() {
    const cams = await this.$store.dispatch(ACTION_CAMPAIGN.LOAD_COMPACT_ITEMS, {
      app_id: this.selectedAppId,
      is_traffic_source: true,
      is_test_mode: this.isTestMode,
    })
    this.campaigns = cams
  }

  async loadTrafficSource() {
    await this.$store.dispatch(ACTION_TRAFFIC_SOURCE.LOAD_ITEMS, {
      app_id: this.selectedAppId,
      campaign_id: this.selectedCampaignId,
      is_test_mode: this.isTestMode,
      date_from: this.dateRange.from,
      date_to: this.dateRange.to,
    })
  }

  onAdd() {
    this.selectedTrafficSource = {
      app_id: this.selectedAppId,
      is_active: !this.isTestMode,
      is_test_mode: this.isTestMode,
    }

    this.modalVisible = true
  }

  onCopy(data: ITrafficSource) {
    const targetCopy: ITrafficSource = cloneDeep(data)
    targetCopy._id = ''
    targetCopy.is_active = !this.isTestMode
    targetCopy.is_test_mode = this.isTestMode
    targetCopy.source_name = targetCopy.source_name + ' Copy'
    this.selectedTrafficSource = targetCopy
    this.modalVisible = true
  }

  onEdit(data: ITrafficSource) {
    this.selectedTrafficSource = data
    this.modalVisible = true
  }

  async onDelete(data: ITrafficSource) {
    this.$q
      .dialog({
        title: this.$t('confirm'),
        message: this.$t('are_you_sure_you_want_to_delete'),
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        const success = await this.$store.dispatch(ACTION_TRAFFIC_SOURCE.DELETE, data._id)
        if (success) {
          this.$q.notify({
            message: this.$t('messages.deleted'),
            color: 'positive',
          })
        }
      })
  }

  onCloseModal() {
    this.modalVisible = false
    this.modalQRCodeVisible = false
    this.selectedTrafficSource = {}
  }

  getTrackingURL(data: ITrafficSource) {
    let accountId = this.selectingApp.account_id
    let liffId = this.selectingApp.liff_id
    if (this.isTestMode) {
      accountId = this.selectingApp.account_id_test
      liffId = this.selectingApp.liff_id_test
    }

    const params = encodeURIComponent(
      `account_id=${accountId}&liff_id=${liffId}&is_test_mode=${this.isTestMode}&source_id=${data._id}&campaign_id=${
        data.campaign_id ?? ''
      }`
    )
    const url = `https://liff.line.me/${liffId}?${params}`
    return url
  }

  onQRCode(data: ITrafficSource) {
    this.selectedTrafficSource = data
    this.modalQRCodeVisible = true
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }
}
