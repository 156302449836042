<template>
  <q-dialog v-model="visible" full-height position="right">
    <q-card style="width: 1000px; max-width: 90vw">
      <q-toolbar>
        <q-toolbar-title>
          {{ modalTitle }}
        </q-toolbar-title>
        <q-btn flat round dense icon="close" @click="onCancel" />
      </q-toolbar>
      <q-card-section>
        <q-form ref="formRef" greedy>
          <div class="max-height-dialog">
            <CLoading :loading="loading" />
            <div class="q-mt-sm">
              <q-chip square color="info" v-if="selectedCampaign">
                <q-avatar color="dark" text-color="white" class="popup--campaign">{{
                  $t('label.popup.campaign')
                }}</q-avatar>
                {{ selectedCampaign.campaign_name }}
              </q-chip>
            </div>
            <label class="text-grey-9 text-bold">{{ $t('label.traffic_source.source_name') }}</label>
            <q-input
              lazy-rules
              :rules="[(val) => (val && val.length > 0) || requiredRule.source_name]"
              outlined
              v-model="form.source_name"
              dense
              :placeholder="$t('label.traffic_source.source_name')"
            >
            </q-input>
          </div>

          <q-space class="q-my-md" />
          <div class="row no-wrap items-center">
            <q-space />
            <div class="q-gutter-sm">
              <q-btn no-caps flat color="white" text-color="black" @click="onCancel">{{ $t('cancel') }}</q-btn>
              <q-btn no-caps color="primary" @click="onSubmit"> {{ $t('save') }} </q-btn>
            </div>
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import cloneDeep from 'lodash/cloneDeep'
import { ICampaign, ITrafficSource } from '@/utils/types'
import { ACTION_CAMPAIGN, ACTION_TRAFFIC_SOURCE } from '@/store/actions'
import CLoading from '@/components/common/ui/CLoading.vue'
import TestModeSelector from '@/components/common/ui/TestModeSelector.vue'
import BaseFormDialogMixin from '../common/mixins/BaseFormDialogMixin.vue'

@Options({
  components: { CLoading, TestModeSelector },
  directives: { maska },
  emits: ['update:onSelect'],
})
export default class TrafficSourceFormModal extends mixins(BaseFormDialogMixin) {
  @Prop()
  data!: ITrafficSource

  @Prop()
  campaignId!: string

  loading = true
  isTestMode = false
  selectedCampaign: ICampaign = {}
  form: ITrafficSource = {
    _id: '',
    source_name: '',
    app_id: '',
    is_active: false,
    is_test_mode: false,
  }

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get requiredRule() {
    const requiredRule = {
      source_name: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.traffic_source.source_name'),
      }),
    }
    return requiredRule
  }

  get modalTitle() {
    return this.isNew ? this.$t('label.traffic_source.add_new') : this.$t('label.traffic_source.edit')
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }

        await this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    const { dispatch } = this.$store
    let item = null
    if (this.isNew) {
      this.form.app_id = this.selectedAppId
      this.form.campaign_id = this.campaignId

      item = await dispatch(ACTION_TRAFFIC_SOURCE.ADD, {
        ...this.form,
      })
    } else {
      item = await dispatch(ACTION_TRAFFIC_SOURCE.UPDATE, {
        _id: this.data._id,
        ...this.form,
      })
    }

    if (item) {
      this.$emit('update:onSelect', item)

      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    this.visible = false
  }

  async created() {
    this.selectedCampaign = await this.$store.dispatch(ACTION_CAMPAIGN.LOAD_ITEM, this.campaignId)

    this.form = { ...this.form, ...cloneDeep(this.data) }
    this.parentForm = this.form
    this.initForm = cloneDeep(this.parentForm)
    this.loading = false
    this.$nextTick(() => {
      this.$refs.formRef.validate()
    })
  }
}
</script>
<style lang="scss" scoped>
.popup {
  background-color: white;
  &--campaign {
    width: 100px;
  }
}
</style>
